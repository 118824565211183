<template>
    <div v-if="isLoading">
      <div class="text-center text-danger screen-center">
        <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
        <strong>Cargando...</strong>
      </div>
    </div>
    <div v-else>
      <div class="breadcrumb"></div>
      <div class="row mb-4">
        <div class="col-sm-12 mb-4">
          <div class="card text-left">
            <div class="card-header" style="height: 80px;">
              <div class="row" style="padding: inherit">
                <div class="col-8 col-md-10 d-flex align-items-center">
                  <h1 class="mb-0"><strong>Cirugia</strong></h1>
                  <button class="btn th-custom-color ml-2" type="button" @click="openModalCirugia(true)">Nuevo</button>
                </div>
                <div class="col-4 col-md-2 d-flex">
                  <button class="btn btn-info w-100 w-md-50">Registros</button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-3 p-3">
                  <div class="card" style="cursor: pointer; border: 1px solid #ddd; border-radius: 10px;">
                    <div  @click="openModalCirugia(true)">
                    <div class="card-header border-radius-top">
                      <div class="row mb-2 ml-1">
                        <div class="col mt-2">
                          <h3>N° 9/24</h3>
                        </div>
                        <div class="col">
                          <div class="row">
                            <span><strong>Hora:</strong> 11:30</span>
                          </div>
                          <div class="row">
                            <span><strong>Fecha:</strong> 10/10/24</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row mx-2">
                        <h3><strong>Cirugia Lengua</strong></h3>
                      </div>
                      <div class="row mx-2">
                        <h6 class="text-success"><strong>Solicitado:</strong> Maria Ledesma</h6>
                      </div>
                    </div>
                  </div>
                    <div class="card-footer no-border">
                      <div class="row">
                        <div class="col-sm-3">
                          <div class="row">
                            <div class="col-sm-1">
                                <i
                                class="nav-icon i-File-Download font-weight-bold text-info"
                                style="cursor: pointer">
                                </i>
                            </div>
                            <div class="col-sm-1">
                                <i
                                class="nav-icon i-Close-Window font-weight-bold text-danger"
                                style="cursor: pointer"
                                ></i>
                            </div>
                          </div>
                        </div>
                        <div class="col mx-2">
                            <div class="row small-text">
                                <span><strong>Origen:</strong> C.E./Internacion: 33/24</span>
                            </div>
                            <div class="row small-text">
                                <strong>Fecha:</strong> 13/10/24
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3 p-3">
                    <div class="card" style="cursor: pointer; border: 1px solid #ddd; border-radius: 10px;">
                      <div @click="openModalCirugia(true)">
                      <div class="card-header border-radius-top bg-yellow">
                        <div class="row mb-2 ml-1">
                          <div class="col mt-2">
                            <h3>N° 9/24</h3>
                          </div>
                          <div class="col">
                            <div class="row">
                              <span><strong>Hora:</strong> 11:30</span>
                            </div>
                            <div class="row">
                              <span><strong>Fecha:</strong> 10/10/24</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row mx-2">
                          <h3><strong>Cirugia Lengua</strong></h3>
                        </div>
                        <div class="row mx-2">
                          <h6 class="text-success"><strong>Solicitado:</strong> Maria Ledesma</h6>
                        </div>
                      </div>
                      </div>
                      <div class="card-footer no-border">
                        <div class="row">
                          <div class="col-sm-3">
                            <div class="row">
                              <div class="col-sm-1">
                                  <i
                                  class="nav-icon i-File-Download font-weight-bold text-info"
                                  style="cursor: pointer">
                                  </i>
                              </div>
                              <div class="col-sm-1">
                                  <i
                                  class="nav-icon i-Close-Window font-weight-bold text-danger"
                                  style="cursor: pointer"
                                  ></i>
                              </div>
                            </div>
                          </div>
                          <div class="col mx-2">
                              <div class="row small-text">
                                  <span><strong>Origen:</strong> C.E./Internacion: 33/24</span>
                              </div>
                              <div class="row small-text">
                                  <strong>Fecha:</strong> 13/10/24
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3 p-3">
                    <div class="card" style="cursor: pointer; border: 1px solid #ddd; border-radius: 10px;">
                      <div @click="openModalCirugia(true)">
                      <div class="card-header border-radius-top bg-success">
                        <div class="row mb-2 ml-1">
                          <div class="col mt-2">
                            <h3>N° 9/24</h3>
                          </div>
                          <div class="col">
                            <div class="row">
                              <span><strong>Hora:</strong> 11:30</span>
                            </div>
                            <div class="row">
                              <span><strong>Fecha:</strong> 10/10/24</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row mx-2">
                          <h3><strong>Cirugia Lengua</strong></h3>
                        </div>
                        <div class="row mx-2">
                          <h6 class="text-success"><strong>Solicitado:</strong> Maria Ledesma</h6>
                        </div>
                      </div>
                      </div>
                      <div class="card-footer no-border">
                        <div class="row">
                          <div class="col-sm-3">
                            <div class="row">
                              <div class="col-sm-1">
                                  <i
                                  class="nav-icon i-File-Download font-weight-bold text-info"
                                  style="cursor: pointer">
                                  </i>
                              </div>
                              <div class="col-sm-1">
                                  <i
                                  class="nav-icon i-Close-Window font-weight-bold text-danger"
                                  style="cursor: pointer"
                                  ></i>
                              </div>
                            </div>
                          </div>
                          <div class="col mx-2">
                              <div class="row small-text">
                                  <span><strong>Origen:</strong> C.E./Internacion: 33/24</span>
                              </div>
                              <div class="row small-text">
                                  <strong>Fecha:</strong> 13/10/24
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TheModalCirugia
      v-model="modeAddItem"
    />
    </div>
  </template>
<script>
import TheModalCirugia from '../components/TheModalCirugia.vue';

export default {
  name: 'cirugia',
  components: {
    TheModalCirugia,
  },
  data() {
    return {
      isLoading: false,
      modeAddItem: false,
    };
  },
  methods: {
    openModalCirugia(toAddNewItem) {
      this.modeAddItem = toAddNewItem;
      this.$bvModal.show('modal-cirugia');
    },
  },
};
</script>
  <style scoped>
  .card {
    border: 1px solid #ddd;
    border-radius: 10px;
  }
  .card-header {
    border-bottom: 1px solid #ddd;
  }
  .card-footer {
    border-top: 1px solid #ddd;
  }
  .border-radius-top {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .text-success {
    color: #28a745;
  }
  .bg-yellow{
    background: yellow;
  }
  .small-text {
    font-size: 12px; /* Ajusta este tamaño según sea necesario */
  }
  .card-footer.no-border {
    border-top: none;
    background-color: #f8f9fa;
  }
  </style>
