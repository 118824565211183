<template>
  <b-modal
    id="modal-cirugia"
    size="lg"
    hide-footer
    @ok="guardarModal"
  >
    <template #modal-title>
      <b>Nueva Solicitud: 1</b>
    </template>
    <div class="row">
      <div class="col-sm-12">
            <div class="row mb-4 mr-0">
              <div class="col-sm-2 text-right">
                <label for="tabSolicitud_comboCategoria" class="col-form-label">
                  <b>Categoria:</b>
                </label>
              </div>
              <div class="col-sm-4 pl-0">
                <multiselect
                  id="tabSolicitud_comboCategoria"
                  v-model="tabSolicitud.categoriaSelected"
                  :options="tabSolicitud.listaCategorias"
                  placeholder=""
                  :allow-empty="false"
                  track-by="id"
                  label="name"
                  select-label=""
                  deselect-label="X"
                >
                  <span slot="caret" v-if="!(tabSolicitud.categoriaSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                </multiselect>
              </div>
              <div class="col-sm-2 text-right">
                <label for="tabSolicitud_comboPaquete" class="col-form-label">
                  <b>Paquete:</b>
                </label>
              </div>
              <div class="col-sm-4 pl-0 pr-4">
                <multiselect
                  id="tabSolicitud_comboPaquete"
                  v-model="tabSolicitud.paqueteSelected"
                  :options="tabSolicitud.listaPaquetes"
                  placeholder=""
                  :allow-empty="false"
                  track-by="id"
                  label="name"
                  select-label=""
                  deselect-label="X"
                >
                  <span slot="caret" v-if="!(tabSolicitud.paqueteSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                </multiselect>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-11 mx-4 pb-3 custom-bg-gray">
                <div class="row">
                  <div class="offset-sm-10 col-sm-1 text-center px-0">
                    <small>Enfermera</small>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-2 text-right">
                    <label for="tabSolicitud_cirujano_1" class="col-form-label">Cirujano:</label>
                  </div>
                  <div class="col-sm-8 pl-0">
                    <input
                      id="tabSolicitud_cirujano_1"
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-1 px-0">
                    <div class="row mx-0">
                      <div class="col-sm-12 text-center px-0">
                        <input
                          type="checkbox"
                          class="align-bottom"
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-2 text-right">
                    <label for="tabSolicitud_ayudante_1" class="col-form-label">1° Ayudante:</label>
                  </div>
                  <div class="col-sm-8 pl-0">
                    <input
                      id="tabSolicitud_ayudante_1"
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-1 px-0">
                    <div class="row mx-0">
                      <div class="col-sm-12 text-center px-0">
                        <input
                          type="checkbox"
                          class="align-bottom"
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-2 text-right">
                    <label
                      for="tabSolicitud_anestesiologo_1"
                      class="col-form-label"
                    >
                      Anestesiólogo:
                    </label>
                  </div>
                  <div class="col-sm-8 pl-0">
                    <input
                      id="tabSolicitud_anestesiologo_1"
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-1 px-0">
                    <div class="row mx-0">
                      <div class="col-sm-12 text-center px-0">
                        <input
                          type="checkbox"
                          class="align-bottom"
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-2 text-right">
                    <label
                      for="tabSolicitud_pediatra_1"
                      class="col-form-label"
                    >Pediatra:</label>
                  </div>
                  <div class="col-sm-8 pl-0">
                    <input
                      id="tabSolicitud_pediatra_1"
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-1 px-0">
                    <div class="row mx-0">
                      <div class="col-sm-12 text-center px-0">
                        <input
                          type="checkbox"
                          class="align-bottom"
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-2 text-right">
                    <label
                      for="tabSolicitud_clinica_1"
                      class="col-form-label"
                    >Clínica:</label>
                  </div>
                  <div class="col-sm-8 pl-0">
                    <input
                      id="tabSolicitud_clinica_1"
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-1 px-0">
                    <div class="row mx-0">
                      <div class="col-sm-12 text-center px-0">
                        <input
                          type="checkbox"
                          class="align-bottom"
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-1 px-0">
                    <button
                      class="btn btn-success px-3 py-0 h-100"
                    >+</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mx-4 mb-2">
              <div class="col-sm-2 text-right">
                <label
                  for="tabSolicitud_comboservicio"
                  class="col-form-label"
                >
                  <b>Servicio:</b>
                </label>
              </div>
              <div class="col-sm-4 pl-0">
                <multiselect
                  id="tabSolicitud_comboservicio"
                  v-model="tabSolicitud.servicioSelected"
                  :options="tabSolicitud.listaServicios"
                  placeholder=""
                  :allow-empty="false"
                  track-by="id"
                  label="name"
                  select-label=""
                  deselect-label="X"
                >
                  <span slot="caret" v-if="!(tabSolicitud.servicioSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                </multiselect>
              </div>
              <div class="col-sm-1 pl-0 text-right">
                <label
                  for="tabSolicitud_fecha"
                  class="col-form-label"
                >
                  <b>Fecha:</b>
                </label>
              </div>
              <div class="col-sm-2 pl-0 pr-2">
                <date-picker
                  :input-attr="{ id: 'tabSolicitud_fecha'}"
                  class="w-100"
                  v-model="tabSolicitud.fecha"
                  lang="es"
                  format="DD/MM/YYYY"
                  type="date"
                  value-type="date"
                ></date-picker>
              </div>
              <div class="col-sm-1 pl-0 text-right">
                <label
                  for="tabSolicitud_hora"
                  class="col-form-label"
                >
                  <b>Hora:</b>
                </label>
              </div>
              <div class="col-sm-2 px-0">
                <input
                  id="tabSolicitud_hora"
                  type="time"
                  class="form-control"
                >
              </div>
            </div>
            <div class="row mx-4">
              <div class="col-sm-2 text-right">
                <label
                  for="tabSolicitud_combosala"
                  class="col-form-label"
                >
                  <b>Sala:</b>
                </label>
              </div>
              <div class="col-sm-4 pl-0">
                <multiselect
                  id="tabSolicitud_combosala"
                  v-model="tabSolicitud.salaSelected"
                  :options="tabSolicitud.listaSalas"
                  placeholder=""
                  :allow-empty="false"
                  track-by="id"
                  label="name"
                  select-label=""
                  deselect-label="X"
                >
                  <span slot="caret" v-if="!(tabSolicitud.salaSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                </multiselect>
              </div>
              <div class="col-sm-2 text-right">
                <label
                  for="tabSolicitud_combotipo"
                  class="col-form-label"
                >
                  <b>Tipo:</b>
                </label>
              </div>
              <div class="col-sm-4 px-0">
                <multiselect
                  id="tabSolicitud_combotipo"
                  v-model="tabSolicitud.tipoSelected"
                  :options="tabSolicitud.listaTipos"
                  placeholder=""
                  :allow-empty="false"
                  track-by="id"
                  label="name"
                  select-label=""
                  deselect-label="X"
                >
                  <span slot="caret" v-if="!(tabSolicitud.tipoSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                </multiselect>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="pl-5 pr-0 col-sm-3">
                <span
                  class="text-warning cursor-pointer"
                >
                  Imprimir Solicitud
                </span>
              </div>
              <div class="col-sm-3">
                <span
                  class="cursor-pointer"
                  :class="design== 1 ? 'text-light':'text-orange'"
                >
                  Imprimir Aprobación
                </span>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-sm-12 text-right">
                <button
                  class="btn text-white mr-2"
                  :class="design== 1 ? 'btn-orange':'btn-dark'"
                  @click="changeColor()">
                  Aprobado
                </button>
                <button class="btn btn-light mr-2">Cancelar</button>
                <button class="btn btn-success">Guardar</button>
              </div>
            </div>
        <!-- <b-tabs content-class="px-0 pb-0
          <b-tab
            :disabled="design === 1"
            title="Arancel"
            title-link-class="px-4"
          >
            <div class="row mb-4 mr-0">
              <div class="col-sm-2 text-right">
                <label for="tabArancel_comboCategoria" class="col-form-label">
                  <b>Categoria:</b>
                </label>
              </div>
              <div class="col-sm-4 pl-0">
                <multiselect
                  id="tabArancel_comboCategoria"
                  v-model="tabArancel.categoriaSelected"
                  :options="tabArancel.listaCategorias"
                  placeholder=""
                  :allow-empty="false"
                  track-by="id"
                  label="name"
                  select-label=""
                  deselect-label="X"
                >
                  <span slot="caret" v-if="!(tabArancel.categoriaSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                </multiselect>
              </div>
              <div class="col-sm-2 text-right">
                <label for="tabArancel_comboPaquete" class="col-form-label">
                  <b>Paquete:</b>
                </label>
              </div>
              <div class="col-sm-4 pl-0 pr-4">
                <multiselect
                  id="tabArancel_comboPaquete"
                  v-model="tabArancel.paqueteSelected"
                  :options="tabArancel.listaPaquetes"
                  placeholder=""
                  :allow-empty="false"
                  track-by="id"
                  label="name"
                  select-label=""
                  deselect-label="X"
                >
                  <span slot="caret" v-if="!(tabArancel.paqueteSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                </multiselect>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-sm-11 mx-4 py-3 custom-bg-gray">
                <div class="row mb-2">
                  <div class="col-sm-2 text-right">
                    <label for="tabArancel_cirujano_1" class="col-form-label">Cirujano:</label>
                  </div>
                  <div class="col-sm-5 px-0">
                    <input
                      id="tabArancel_cirujano_1"
                      type="text"
                      class="form-control"
                      disabled
                    >
                  </div>
                  <div class="col-sm-2 px-1">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                    >
                  </div>
                  <div class="col-sm-2 pl-0">
                    <vue-numeric
                      class="form-control text-right"
                      v-model="tabArancel.cirujanoMonto"
                      separator=","
                      :precision="2"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-2 text-right">
                    <label for="tabArancel_ayudante_1" class="col-form-label">1° Ayudante:</label>
                  </div>
                  <div class="col-sm-5 px-0">
                    <input
                      id="tabArancel_ayudante_1"
                      type="text"
                      class="form-control"
                      disabled
                    >
                  </div>
                  <div class="col-sm-2 px-1">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                    >
                  </div>
                  <div class="col-sm-2 pl-0">
                    <vue-numeric
                      class="form-control text-right"
                      v-model="tabArancel.primerAyudanteMonto"
                      separator=","
                      :precision="2"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-2 text-right">
                    <label
                      for="tabArancel_anestesiologo_1"
                      class="col-form-label"
                    >
                      Anestesiólogo:
                    </label>
                  </div>
                  <div class="col-sm-5 px-0">
                    <input
                      id="tabArancel_anestesiologo_1"
                      type="text"
                      class="form-control"
                      disabled
                    >
                  </div>
                  <div class="col-sm-2 px-1">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                    >
                  </div>
                  <div class="col-sm-2 pl-0">
                    <vue-numeric
                      class="form-control text-right"
                      v-model="tabArancel.anestesiologoMonto"
                      separator=","
                      :precision="2"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-2 text-right">
                    <label
                      for="tabArancel_pediatra_1"
                      class="col-form-label"
                    >Pediatra:</label>
                  </div>
                  <div class="col-sm-5 px-0">
                    <input
                      id="tabArancel_pediatra_1"
                      type="text"
                      class="form-control"
                      disabled
                    >
                  </div>
                  <div class="col-sm-2 px-1">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                    >
                  </div>
                  <div class="col-sm-2 pl-0">
                    <vue-numeric
                      class="form-control text-right"
                      v-model="tabArancel.pediatraMonto"
                      separator=","
                      :precision="2"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-2 text-right">
                    <label
                      for="tabArancel_clinica_1"
                      class="col-form-label"
                    >Clínica:</label>
                  </div>
                  <div class="col-sm-5 px-0">
                    <input
                      id="tabArancel_clinica_1"
                      type="text"
                      class="form-control"
                      disabled
                    >
                  </div>
                  <div class="col-sm-2 px-1">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                    >
                  </div>
                  <div class="col-sm-2 pl-0">
                    <vue-numeric
                      class="form-control text-right"
                      v-model="tabArancel.pediatraMonto"
                      separator=","
                      :precision="2"
                      placeholder=""
                    />
                  </div>
                  <div class="col-sm-1 px-0">
                    <button
                      class="btn btn-success px-3 py-0 h-100"
                    >+</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-sm-12 text-right">
                <button class="btn btn-light mr-2">Cancelar</button>
                <button class="btn btn-success">Guardar</button>
              </div>
            </div>
          </b-tab>

          <b-tab
            :disabled="design === 1"
            title="Insumos"
            title-link-class="px-4"
          >
            <div class="row mb-4 mx-0">
              <div class="col-sm-2 text-right">
                <label
                  for="tabInsumos_comboProducto"
                  class="col-form-label"
                >
                  <b>Producto:</b>
                </label>
              </div>
              <div class="col-sm-5 pl-0">
                <multiselect
                  id="tabInsumos_comboProducto"
                  v-model="tabInsumos.productoSelected"
                  :options="tabInsumos.listaProductos"
                  placeholder=""
                  :allow-empty="false"
                  track-by="id"
                  label="name"
                  select-label=""
                  deselect-label="X"
                >
                  <span slot="caret" v-if="!(tabInsumos.productoSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                </multiselect>
              </div>
              <div class="col-sm-1  px-0 text-right">
                <label
                  for="tabInsumos_cantidad"
                  class="col-form-label"
                >
                  <b>Cantidad:</b>
                </label>
              </div>
              <div class="col-sm-2">
                <vue-numeric
                  id="tabInsumos_cantidad"
                  class="form-control text-right"
                  v-model="tabInsumos.cantidad"
                  thousand-separator=""
                />
              </div>
              <div class="col-sm-2">
                <button class="btn btn-success btn-block">Agregar</button>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-sm-12">
                <b-table
                  head-variant="secondary"
                  class="table table-striped table-hover table-bordered"
                  :fields="tabInsumos.fields"
                  :items="tabInsumos.listaTabla"
                ></b-table>
              </div>
            </div>
            <div class="row mx-0 mb-2">
              <div class="offset-sm-8 col-sm-2 text-right pr-0">
                <label
                  for="tabInsumos_costoFinal"
                  class="col-form-label"
                >
                  Costo Final:
                </label>
              </div>
              <div class="col-sm-2">
                <input
                  id="tabInsumos_costoFinal"
                  type="text"
                  class="form-control"
                  disabled
                >
              </div>
            </div>
            <div class="row mx-0">
              <div class="offset-sm-8 col-sm-2 text-right pr-0">
                <label
                  for="tabInsumos_costoCotizado"
                  class="col-form-label"
                >
                  Costo Cotizado:
                </label>
              </div>
              <div class="col-sm-2">
                <input
                  id="tabInsumos_costoCotizado"
                  type="text"
                  class="form-control"
                  disabled
                >
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-sm-12 text-right">
                <button class="btn btn-light mr-2">Cancelar</button>
                <button class="btn btn-success">Guardar</button>
              </div>
            </div>
          </b-tab>
        </b-tabs> -->
      </div>
    </div>
  </b-modal>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import Multiselect from 'vue-multiselect';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
  name: 'ModalCirugia',
  components: {
    DatePicker,
    Multiselect,
  },
  model: {
    prop: 'modeAddItem',
    event: 'changeMode',
  },
  props: {
    modeAddItem: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabSolicitud: {
        categoriaSelected: null,
        listaCategorias: [],
        paqueteSelected: null,
        listaPaquetes: [],
        servicioSelected: null,
        fecha: null,
        listaServicios: [],
        salaSelected: null,
        listaSalas: [],
        tipoSelected: { id: 1, name: 'Seguro' },
        listaTipos: [
          { id: 1, name: 'Seguro' },
          { id: 2, name: 'Clínico' },
          { id: 3, name: 'Externo' },
        ],
      },
      tabArancel: {
        categoriaSelected: null,
        paqueteSelected: null,
        listaCategorias: [],
        listaPaquetes: [],
        cirujanoMonto: 0,
        primerAyudanteMonto: 0,
        anestesiologoMonto: 0,
        pediatraMonto: 0,
        clinicaMonto: 0,
      },
      tabInsumos: {
        productoSelected: null,
        listaProductos: [],
        cantidad: 0,
        fields: [
          {
            key: 'numero', label: 'N°', class: 'text-center', thStyle: { width: '10%' },
          },
          {
            key: 'producto', label: 'Producto', class: 'text-center', thStyle: { width: '40%' },
          },
          {
            key: 'cantidad', label: 'Cantidad', class: 'text-center', thStyle: { width: '15%' },
          },
          {
            key: 'usuario', label: 'Usuario', class: 'text-center', thStyle: { width: '35%' },
          },
        ],
        listaTabla: [
          {
            numero: null,
            producto: '',
            cantidad: null,
            usuario: '',
          },
          {
            numero: null,
            producto: '',
            cantidad: null,
            usuario: '',
          },
          {
            numero: null,
            producto: '',
            cantidad: null,
            usuario: '',
          },
          {
            numero: null,
            producto: '',
            cantidad: null,
            usuario: '',
          },
          {
            numero: null,
            producto: '',
            cantidad: null,
            usuario: '',
          },
        ],
      },
      design: 1,
    };
  },
  methods: {
    addItemCirugia() {
      const data = {
        id: 0,
      };
      this.$emit('addNewItem', data);
    },
    changeColor() {
      this.design = 2;
    },
    guardarModal() {
      if (this.modeAddItem) {
        this.addItemCirugia();
        this.$emit('changeMode', false);
      } else {
        const data = { };
        this.$emit('onChangeData', data);
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.custom-bg-gray {
  background: #eee;
}
</style>
